<section class="breadcrumbs-v5 content-lg">
    <div class="container" style="text-align:center"><br /><br />
        <h2 class="breadcrumbs-v5-title" style="margin-top:5%;color:white; font-family:proxima_novaregular">{{"SITEMAP.HOW_IT_WORKS" | translate | uppercase}}</h2>
        <span class="breadcrumbs-v5-subtitle" style="color:white;font-family:proxima_novaregular">{{ "SITEMAP.IN_5_STEPS" | translate }}</span>
        <span class="breadcrumbs-v5-subtitle">
            <i class="fa fa-angle-down"></i>
        </span>

    </div>
</section>
<div class="container">
    <!-- Extra Small Pogress Bar -->
    <div class="row" style="margin-top:4%" id="4">
        <div class="heading-v1 text-center margin-b-80" style="margin-top:15px;">
            <p class="heading-v1-text" >{{ "SITEMAP.STEP1" | translate }}</p>
            <h2 class="breadcrumbs-v5-title" style="margin-top:2%; ">{{ "SITEMAP.DESIGN" | translate | uppercase }}</h2>
            <p class="heading-v1-text" >{{ "SITEMAP.STEP1_TEXT" | translate }}</p>
            <p style="margin-top:4%"><img src="assets/images/down-arrow.png" /></p>
        </div>

        <div class="heading-v1 text-center margin-b-80" style="margin-top:15px;">
            <p class="heading-v1-text" >{{ "SITEMAP.STEP2" | translate }}</p>
            <h2 class="breadcrumbs-v5-title" style="margin-top:2%;"> {{"SITEMAP.COLOUR_SIZE_YARN"  | translate | uppercase}}</h2>
            <p class="heading-v1-text" >{{ "SITEMAP.STEP2_TEXT" | translate }}</p>
            <p style="margin-top:4%"><img  src="assets/images/down-arrow.png" /></p>
        </div>

        <div class="heading-v1 text-center margin-b-80" style="margin-top:15px;">
            <p class="heading-v1-text" >{{ "SITEMAP.STEP3" | translate }}</p>
            <h2 class="breadcrumbs-v5-title" style="margin-top:2%; ">{{"SITEMAP.GET_A_QUOTE" | translate | uppercase}}</h2>
            <p class="heading-v1-text" >
                {{ "SITEMAP.STEP3_TEXT" | translate }}
            </p>
            <p style="margin-top:4%"><img src="assets/images/down-arrow.png" /></p>
        </div>
        <div class="heading-v1 text-center margin-b-80" style="margin-top:15px;">
            <p class="heading-v1-text" >{{ "SITEMAP.STEP4" | translate }}</p>
            <h2 class="breadcrumbs-v5-title" style="margin-top:2%;"> {{ "SITEMAP.CUSTOM_MADE" | translate | uppercase}}</h2>
            <p class="heading-v1-text" >
                {{ "SITEMAP.STEP4_TEXT" | translate }}
            </p>
            <p style="margin-top:4%"><img src="assets/images/down-arrow.png" /></p>
        </div>
        <div class="heading-v1 text-center margin-b-80" style="margin-top:15px;padding-bottom: 1rem;">
            <p class="heading-v1-text" >{{ "SITEMAP.STEP5" | translate }}</p>
            <h2 class="breadcrumbs-v5-title" style="margin-top:2%; ">{{ "SITEMAP.UNROLL" | translate | uppercase}}</h2>
            <p class="heading-v1-text" style="margin-bottom:50px;">
                {{"SITEMAP.STEP5_TEXT" | translate}}
            </p>
            <a style="width:30%; height:50px; padding-top:15px;padding-bottom:1rem; " id="btnsave"
                class="btn btn-sm btn-secondary" routerLink="/home">{{"SITEMAP.GET_STARTED" | translate}}</a>
        </div>
    </div>