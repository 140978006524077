import { Component, OnInit } from '@angular/core';
import { User, UserService } from 'src/app/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/core/services/app.config';

declare var initMenu: any;

@Component({
  selector: 'bespoke-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  user?: User;
  appConfig = AppConfig;
  
  constructor(
    public translate: TranslateService,
    private router: Router,
    private userService: UserService
  ) { }

  logout(): void {
    this.userService.purgeAuth();
    this.router.navigateByUrl('/login');
  }

  ngOnInit(): void {
    this.userService.isAuthenticated.subscribe((isLogedIn) => {
      this.isLoggedIn = isLogedIn;
      if (isLogedIn) {
        initMenu();
      }
    });
    // tslint:disable-next-line: deprecation
    this.userService.currentUser.subscribe((userData) => {
      this.user = userData;
    });
  }
}
