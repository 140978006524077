import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../core/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bespoke-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnDestroy {
  disbaled = true;
  private token: string = '';
  private email: string = '';
  private subscription!: Subscription;
  constructor(private fb: FormBuilder,
    private toaster: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private activedRoute: ActivatedRoute
  ) {
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }
  changePasswordForm!: FormGroup;
  ngOnInit(): void {
    this.subscription = this.activedRoute.queryParams.subscribe((item) => {
      this.token = item.token;
      this.email = item.email;
    });
  }
  submitForm(): void {
    const value = this.changePasswordForm.value;
    if (value.password.length < 8 || value.confirmpassword.length < 8) {
      this.toaster.error('password length should be minimium 8 char');
      this.changePasswordForm.reset();
      return;
    }
    if (value.password !== value.confirmpassword) {
      this.toaster.error('password mismatch');
      this.changePasswordForm.reset();
      return;
    }
    const options = {
      email: this.email,
      password: value.password,
      token: this.token
    }
    this.apiService.post('/Auth/reset-password', options).subscribe((res) => {
      this.toaster.success('Password Updated Successfully');
      this.router.navigate(['/login']);
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
