import { NgModule} from '@angular/core';
import { AuthComponent } from './login-register/auth.component';
import { NoAuthGuard } from './no-auth-guard.service';
import { SharedModule } from '../shared';
import { AuthRoutingModule } from './auth-routing.module';
import {
  NgbModule,
  NgbNav,
  NgbNavItem
} from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerificationComponent } from './verification/verification.component';
import { CommonModule } from '@angular/common';
import { HomeComponent } from '../home/home.component';
import { SiteMapComponent } from './site-map/site-map.component';

@NgModule({
  imports: [CommonModule, SharedModule, AuthRoutingModule, NgbModule],
  declarations: [AuthComponent, ForgotPasswordComponent,  VerificationComponent,HomeComponent,SiteMapComponent],
  providers: [NoAuthGuard, NgbNav, NgbNavItem],
})
export class AuthModule {}
