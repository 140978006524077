import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from './app.config';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private toasterService: ToastrService,
    private translate: TranslateService
  ) {}

  private formatErrors(error: any): Observable<any> {
    let errorKey: string = 'Unknown Error';
    if (error) {
      if (error.status == 400) errorKey = `SERVERERRORS.${error.error.Message}`;
      else if (error.status == 500) {
        errorKey = `SERVERERRORS.${error.error.Message}`;
      }

      if (error.status !== 401) {
        this.translate.get(errorKey).subscribe((res) => {
          this.toasterService.error(res);
        });
      }
    }
    return throwError(error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${AppConfig.baseUrl}${path}`, { params })
      .pipe(catchError(this.formatErrors.bind(this)));
  }

  // tslint:disable-next-line: ban-types
  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(`${AppConfig.baseUrl}${path}`, body)
      .pipe(catchError(this.formatErrors.bind(this)));
  }

  // tslint:disable-next-line: ban-types
  post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(`${AppConfig.baseUrl}${path}`, body)
      .pipe(catchError(this.formatErrors.bind(this)));
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete(`${AppConfig.baseUrl}${path}`)
      .pipe(catchError(this.formatErrors.bind(this)));
  }

  postOrPut(method: string, path: string, body: Object = {}): Observable<any> {
    if (method == 'PUT') return this.put(path, body);
    else return this.post(path, body);
  }

  getFile(url: string): Observable<any> {
    return this.http.get(AppConfig.baseUrl + url, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
