<div class="container mt-2">
  <div class="row justify-content-center">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <div class="heading-panel mb-4">
        <h4 class="main-title text-center">
          {{ "COMMON.SETNEW_PWD" | translate }}
        </h4>
      </div>
      <form [formGroup]="changePasswordForm" (ngSubmit)="submitForm()">
        <fieldset>
          <fieldset class="form-group">
            <input
              min-length="8"
              formControlName="password"
              placeholder="New Password"
              class="form-control form-control-lg"
              type="password"
            />
          </fieldset>
          <fieldset class="form-group mt-1">
            <input
              min-length="8"
              formControlName="confirmpassword"
              placeholder="Confirm Password"
              class="form-control form-control-lg mt-1"
              type="password"
            />
          </fieldset>
          <button
            [disabled]="!changePasswordForm.valid"
            class="btn btn-outline-dark btn-lg btn-block btn-margin"
            type="submit"
          >
            {{ "COMMON.SUBMIT" | translate }}
          </button>
        </fieldset>
      </form>
    </div>
  </div>
</div>
