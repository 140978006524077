import { Injectable } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({ providedIn: 'root' })
export class LoaderService {

    loading: boolean;
    constructor(
        private loader: NgxUiLoaderService,
    ) {

    }

    start() {
        this.loader.start();
        this.loading = true;
    }

    stop() {
        this.loader.stop();
        this.loading = false;
    }
}
