import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService, UserService } from '../../core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  authType = '';
  title = '';
  authForm!: FormGroup;
  newUserForm!: FormGroup;
  submitted = false;
  existingUserForm!: FormGroup;
  active = 1;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private toasterService: ToastrService,
    private gs: GenericService
  ) {
    this.existingUserForm = this.fb.group(
      {
        name: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: this.MustMatch('password', 'confirmPassword'),
      }
    );

    this.newUserForm = this.fb.group(
      {
        name: ['', Validators.required],
        email: ['', Validators.required],
        mobileNo: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      { validator: this.MustMatch('password', 'confirmPassword') }
    );

    this.authForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.newUserForm.controls;
  }

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.route.url.subscribe((data) => {
      // Get the last piece of the URL (it's either 'login' or 'register')
      this.authType = data[data.length - 1].path;
      // Set a title for the page accordingly
      this.title = this.authType === 'login' ? 'Sign in' : 'Sign up';
      // add form control for username if this is the register page
    });
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password')?.value !== c.get('confirmedPassword')?.value) {
      return { invalid: true };
    }
    return { invalid: false };
  }
  submitForm(): void {
    const credentials = this.authForm.value;
    this.userService
      .attemptAuth(this.authType, credentials)
      .subscribe((res) => {
        this.router.navigateByUrl('/home');
      });
  }

  submitNewUserForm(): void {
    const data = this.newUserForm.value;
    this.userService
      .newUserRegister(data)
      // tslint:disable-next-line: deprecation
      .subscribe(() => {
        this.gs.success('REGISTER.NEWUSERREQUESTSUCCESS');
        this.router.navigateByUrl('/login');
      });
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
