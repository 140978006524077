import { Component, OnInit } from '@angular/core';
import { ApiService, GenericService, UserService } from '../core';
import { Router } from '@angular/router';

@Component({
  selector: 'bespoke-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  categoryList: any;
  patternList: any;
  groupColors$: any;
  user: any;
  mappedColors: any;
  searchText = '';
  p=1;

  public carouselImages = [
    { id: 1, url: 'assets/images/slider/1.jpg' },
    { id: 2, url: 'assets/images/slider/2.jpg' },
    { id: 3, url: 'assets/images/slider/3.jpg' },
    { id: 4, url: 'assets/images/slider/4.jpg' },
    { id: 5, url: 'assets/images/slider/5.jpg' },
  ];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private gs: GenericService,
  ) { }

  ngOnInit() {
    this.getCatetory();
    this.getPatterns(0);
    this.user = this.userService.getCurrentUser();
  }

  getCatetory(): void {
    this.apiService.get(`/master/category-list`).subscribe((data) => {
      this.categoryList = data;
    });
  }

  search() {
    this.getPatterns(0, this.searchText);
  }

  getPatterns(categoryId: number, searchText?: string): void {
    this.p=1;
    let url = `/master/pattern-master?categoryId=${categoryId}`;
    if (searchText) {
      url += `&searchText=${searchText}`;
    }
    else {
      this.searchText = '';
    }

    this.apiService
      .get(url)
      .subscribe((data) => {
        this.patternList = data;
        this.patternList?.forEach((x: any) => {
          var res = x?.patternColorGroup
            .map((item: any) => item)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );
          x.colorGroup = res;
        });
      });
  }

  createWish(pattern: any) {
    if (this.user != null) {
      var item = { patternId: pattern.id };
      this.apiService.post(`/wish/create-wish`, item).subscribe((data) => {
        this.gs.success('WISH.CREATE');
        this.router.navigate(['/order/wish-list']);
      });
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  createOrder(pattern: any) {
    this.router.navigate(['/order/create-order', pattern.id]);
  }
  getColor(rgb: string) {
    return `rgb(${rgb})`;
  }
}
