<div class="auth-page">
  <div class="main-content-area clearfix">
    <section class="section-padding error-page pattern-bg">
      <div
        class="container"
        *ngIf="authType !== 'register'; else registerTemplate"
      >
        <div class="row">
          <div class="col-md-3 col-md-push-4 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-md-push-4 col-sm-6 col-xs-12">
            <div class="heading-panel mb-3">
              <h3
                class="main-title text-center"
                *ngIf="authType === 'register'"
              >
                {{ "REGISTER.REGISTERTOYOURACCOUNT" | translate }}
              </h3>
              <h3 class="main-title text-center" *ngIf="authType === 'login'">
                {{ "LOGIN.LOGINTOYOURACCOUNT" | translate }}
              </h3>
            </div>
            <div class="mb-3">
              <ng-container *ngIf="authType === 'login'">
                {{ "LOGIN.LOGIN_INSTR" | translate }}
              </ng-container>
            </div>
            <div class="form-grid" [class.bt]="authType === 'login'">
              <form [formGroup]="authForm" (ngSubmit)="submitForm()">
                <fieldset>
                  <fieldset class="form-group">
                    <input
                      formControlName="userName"
                      [placeholder]="'COMMON.USERNAME' | translate"
                      class="form-control form-control-lg"
                      type="text"
                    />
                  </fieldset>
                  <fieldset class="form-group">
                    <input
                      formControlName="password"
                      [placeholder]="'COMMON.PASSWORD' | translate"
                      class="form-control form-control-lg"
                      type="password"
                    />
                  </fieldset>
                  <fieldset class="form-group">
                    <a class="forgot-password" routerLink="/forgot-password">
                      {{ "FORGOTPWD.CREATE_OR_RESTORE_PWD" | translate }}
                    </a>
                  </fieldset>
                  <button
                    class="btn btn-outline-dark btn-lg btn-block"
                    [disabled]="!authForm.valid"
                    type="submit"
                  >
                    {{ "LOGIN.LOGIN" | translate }}
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
          <div class="col-md-3 col-md-push-4 col-sm-6 col-xs-12"></div>
        </div>
      </div>
      <ng-template #registerTemplate>
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-6 col-xs-12">
              <div class="col-xs-12 col-md-12 col-lg-12">
                <div class="heading-panel">
                  <h3 class="main-title ml-2 text-center">
                    {{ "REGISTER.REGISTRATIONMESSAGE" | translate }}
                  </h3>
                </div>
                <ul class="nav-tabs">
                  <li>
                    <div class="form-grid">
                      <div class="container">
                        <div class="row"></div>
                        <div class="row">
                          <div class="col-md-7">
                            <form
                              [formGroup]="newUserForm"
                              (ngSubmit)="submitNewUserForm()"
                              class="container"
                            >
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-12 h5">
                                      <strong>{{
                                        "REGISTER.USERINFORMATION" | translate
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <input
                                          formControlName="name"
                                          [placeholder]="
                                            'COMMON.NAME' | translate
                                          "
                                          class="form-control"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <input
                                          formControlName="email"
                                          [placeholder]="
                                            'COMMON.EMAIL' | translate
                                          "
                                          class="form-control"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <input
                                          formControlName="mobileNo"
                                          [placeholder]="
                                            'COMMON.PHONE' | translate
                                          "
                                          class="form-control"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <input
                                      type="password"
                                      formControlName="password"
                                      [placeholder]="
                                        'COMMON.PASSWORD' | translate
                                      "
                                      class="form-control"
                                      [ngClass]="{
                                        'is-invalid':
                                          submitted && f.password.errors
                                      }"
                                    />
                                    <div
                                      *ngIf="submitted && f.password.errors"
                                      class="invalid-feedback"
                                    >
                                      <div *ngIf="f.password.errors.required">
                                        {{"COMMON.PASSWORD_REQ" | translate}}
                                      </div>
                                      <div *ngIf="f.password.errors.minlength">
                                        {{"COMMON.PASSWORD_LENGTH" | translate}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <input
                                      type="password"
                                      formControlName="confirmPassword"
                                      title="Please re-enter your password"
                                      [placeholder]="
                                        'COMMON.CONFIRMPASSWORD' | translate
                                      "
                                      class="form-control"
                                      [ngClass]="{
                                        'is-invalid':
                                          submitted && f.confirmPassword.errors
                                      }"
                                    />
                                    <div
                                      *ngIf="
                                        submitted && f.confirmPassword.errors
                                      "
                                      class="invalid-feedback"
                                    >
                                      <div
                                        *ngIf="
                                          f.confirmPassword.errors.required
                                        "
                                      >
                                        {{"COMMON.CONF_PASSWORD_REQ" | translate}
                                      </div>
                                      <div
                                        *ngIf="
                                          f.confirmPassword.errors.mustMatch
                                        "
                                      >
                                      {{"COMMON.PASS_MUST_MATCH" | translate}                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <button
                                    [disabled]="!newUserForm.valid"
                                    class="btn btn-outline-dark btn-lg btn-block"
                                    type="submit"
                                  >
                                    {{ "COMMON.SENDREQUEST" | translate }}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div class="col-md-1"></div>
                          <div class="col-md-4">
                            <div class="row">
                              <div
                                class="uppercase col-md-12 h4 text-center border-top border-dark pt-3"
                              >
                                <strong>{{ "COMMON.INFO" | translate }}</strong>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 gray p-4">
                                {{ "REGISTER.REGISTRATIOINFO" | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </section>
  </div>
</div>
