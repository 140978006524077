<div class="page">
  <div class="carousel-container">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let img of carouselImages">
        <div class="card profile-header mb-1">
          <img [src]="img.url" class="img-fluid" alt="Random first slide" />
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="row content">
    <div class="col-md-3 mt-3">
      <div class="card">
        <div class="card-body">
          <div id="categories">
            <h6 class="filter-title">{{"HOME.SEARCH_BY_PATTERN" | translate}}</h6>
            <form class="d-flex">
              <input class="form-control me-2" name="search" type="search" placeholder="Search" aria-label="Search"
                [(ngModel)]="searchText" (keydown.enter)="search()">
              <button type="button" class="btn btn-primary" [disabled]="!searchText" (click)="search()">
                <i class="fas fa-search"></i>
              </button>
            </form>
            <ul class="list-unstyled">
              <li>
                <div class="category-item">
                  <i class="fa fa-angle-double-right"></i>
                  <label class="custom-control-label pointer" for="category1" (click)="getPatterns(0)">All</label>
                </div>
              </li>
              <li *ngFor="let item of categoryList; let i = index">
                <div class="category-item">
                  <i class="fa fa-angle-double-right pointer"></i>
                  <label class="custom-control-label pointer" for="category1" (click)="getPatterns(item.id)">{{
                    item.name }}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="row">
        <div *ngFor="let pattern of patternList | paginate:{itemsPerPage: 12, currentPage:p}" class="col-md-3">
          <div class="card pattern-card mt-3">
            <div class="card-body">
              <div class="image-container">
                <img class="pointer" (click)="createOrder(pattern)" src="{{ pattern?.patternImage }}"
                  alt="img-placeholder" />
              </div>
              <div>
                <div class="pattern-title">
                  {{ pattern?.name }}
                  <a class="btn-wishlist"><i class="fas fa-heart" (click)="createWish(pattern)"></i></a>
                </div>
                <div class="color-balls-container">
                  <div *ngFor="let col of pattern?.colorGroup" class="color-ball" 
                    [style.background-color]="getColor(col.qdrgbCode)"  placement="bottom" ngbTooltip="{{col.mappedQDCode}}"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="pagination" class="mt-3" *ngIf="patternList?.length">
          <pagination-controls (pageChange)="p=$event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>