<header class="vanilla-mega-menu" id="header">
  <div class="wrapper container">
    <div class="wrapper-left">
      <a routerLink="/home" class="brand">

        <!-- {{"COMMON.APPLICATION_NAME" | translate | uppercase}} -->
        <img src="assets/images/logo.jpg" style="height:40px" />
      </a>
    </div>

    <div class="wrapper-center" [hidden]="user">
      <span class="overlay"></span>
      <nav class="navbar" id="navbar">
        <div class="menu-header">
          <span class="menu-arrow"><i class="fas fa-chevron-left"></i></span>
          <span class="menu-title"></span>
        </div>
        <ul class="menu-inner">
          <li class="menu-item">
            <a routerLink="/sitemap" class="menu-link">{{"MENU.HOW_IT_WORKS" | translate}}</a>
          </li>
          <!-- <li class="menu-item">
            <a routerLink="/login" class="menu-link">{{"LOGIN.LOGIN" | translate}}</a>
          </li> -->
          <li class="menu-item" *ngIf="appConfig.allowRegister">
            <a routerLink="/register" class="menu-link">{{"REGISTER.REGISTER" | translate}}</a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="wrapper-center" [hidden]="!user">
      <span class="overlay"></span>
      <nav class="navbar" id="navbar">
        <div class="menu-header">
          <span class="menu-arrow"><i class="fas fa-chevron-left"></i></span>
          <span class="menu-title"></span>
        </div>
        <ul class="menu-inner">
          <li class="menu-item">
            <a routerLink="/home" class="menu-link">{{"HOME.HOME" | translate}}</a>
          </li>
          <li class="menu-item">
            <a routerLink="/sitemap" class="menu-link">{{"MENU.HOW_IT_WORKS" | translate}}</a>
          </li>
          <li class="menu-item menu-dropdown" *ngIf="user?.Role === 'Admin'">
            <span class="menu-link">{{ "ADMIN.PATTERNMASTER" | translate
              }}<i class="fas fa-chevron-down"></i></span>
            <div class="submenu megamenu">
              <ul class="submenu-list">
                <li class="submenu-item">
                  <a routerLink="/admin/pattern-list" class="submenu-link">{{
                    "ADMIN.PATTERNLIST" | translate
                    }}</a>
                </li>
                <li class="submenu-item">
                  <a routerLink="/admin/pattern-master" class="submenu-link">{{
                    "ADMIN.NEWPATTERN" | translate
                    }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="menu-item">
            <a routerLink="/order/order-list" class="menu-link">{{
              "CUSTOMER.ORDERSUMMARY" | translate
              }}</a>
          </li>
          <li class="menu-item">
            <a routerLink="/order/wish-list" class="menu-link">{{
              "CUSTOMER.WISHLIST" | translate
              }}</a>
          </li>

          <li class="menu-item menu-dropdown">
            <span class="menu-link">{{ "CUSTOMER.ACCOUNT" | translate
              }}<i class="fas fa-chevron-down"></i></span>
            <div class="submenu megamenu">
              <ul class="submenu-list">
                <li class="submenu-item">
                  <a routerLink="/user/address-book" class="submenu-link">{{
                    "CUSTOMER.ADDRESSBOOK" | translate
                    }}</a>
                </li>
                <li class="submenu-item">
                  <a routerLink="/user/profile" class="submenu-link">{{
                    "CUSTOMER.PROFILE" | translate
                    }}</a>
                </li>
                <li class="submenu-item" *ngIf="user?.Role === 'Admin'">
                  <a routerLink="/admin/user-list" class="submenu-link">{{
                    "USERS.LIST" | translate
                    }}</a>
                </li>
                <li class="submenu-item">
                  <a (click)="logout()" class="submenu-link">{{
                    "COMMON.LOGOUT" | translate
                    }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div class="wrapper-right" [hidden]="!user">
      <div class="burger" id="burger">
        <span class="burger-line"></span>
        <span class="burger-line"></span>
        <span class="burger-line"></span>
      </div>
    </div>
  </div>
</header>