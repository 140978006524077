import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { HeaderComponent, FooterComponent, SharedModule } from './shared';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// AoT requires an exported function for factories
// tslint:disable-next-line: typedef
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const loaderConfiguration: NgxUiLoaderConfig = {
	bgsColor: '#000000',
	bgsOpacity: 0.2,
	bgsPosition: 'center-center',
	bgsSize: 60,
	bgsType: 'ball-spin-clockwise',
	blur: 2,
	delay: 0,
	fastFadeOut: true,
	fgsColor: '#000000',
	fgsPosition: 'center-center',
	fgsSize: 60,
	fgsType: 'ball-spin-clockwise',
	gap: 40,
	logoPosition: 'bottom-center',
	logoSize: 60,
	masterLoaderId: 'master',
	overlayBorderRadius: '0',
	overlayColor: 'rgba(240, 240, 240, 0.8)',
	hasProgressBar: false,
	text: 'Loading...',
	textColor: '#000000',
	textPosition: 'center-center',
	maxTime: -1,
	minTime: 300
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
   ],
  imports: [
    BrowserModule,
    ToastrModule,
    CoreModule,
    SharedModule,
    AuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbTooltipModule,
    NgxUiLoaderModule.forRoot(loaderConfiguration),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [NgxUiLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
