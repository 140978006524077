import { Injectable } from '@angular/core';

declare var globalConfig: any; 

@Injectable()
export class AppConfig
{
    static get baseUrl()
    {
        return globalConfig.baseUrl;
    }
    static get shapes()
    {
        return globalConfig.shapes;
    }
    static get units()
    {
        return globalConfig.units;
    }

    static get allowRegister()
    {
        return globalConfig.allowRegister;
    }
}
