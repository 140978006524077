import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../core/services/api.service';
import { GenericService } from '../../core';

@Component({
  selector: 'bespoke-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  forgotPasswordForm!: FormGroup;
  constructor(private gs: GenericService, private fb: FormBuilder, private apiService: ApiService) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

  submit(): void {
      const email = this.forgotPasswordForm.value.email;
    if (!email) {
      this.gs.error('COMMON.EMAIL_REQUIRED');
      return;
    }
    this.apiService.get(`/Auth/send-reset-password-link?email=${email}`).subscribe(
      () => {
        this.gs.success('FORGOTPASSWORD.RESETPWDLINKSENT');
      });
  }
}
