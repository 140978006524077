import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GenericService {
  constructor(
    private toasterService: ToastrService,
    private translate: TranslateService
  ) {}

  error(errorKey: string) {
    this.translate.get(errorKey).subscribe((res) => {
      this.toasterService.error(res);
    });
  }
  success(errorKey: string) {
    this.translate.get(errorKey).subscribe((res) => {
      this.toasterService.success(res);
    });
  }

  countTotalPages(totalRecords: number, pageSize: number): number {
    let totalPages = parseInt((totalRecords / 10).toString());
    if (totalRecords % 10) totalPages++;

    return totalPages;
  }

  getCustomerServiceUrl(lang: string): string {
    let customerServicUrl: string;
    if (lang === 'fi') {
      customerServicUrl = 'https://bespoke.fi/yhteystiedot/#asiakaspalvelu';
    } else if (lang === 'sv') {
      customerServicUrl = 'https://bespoke.fi/sv/kontaktuppgifter/#kundservice';
    } else {
      customerServicUrl = 'https://bespoke.fi/en/contact/#customer-service';
    }
    return customerServicUrl;
  }

  translateColumnNames(columnDefs: any[]) {
    columnDefs.forEach((x) => {
      if (!x.key) {
        return;
      }
      this.translate.get(x.key).subscribe((res) => {
        x.headerName = res;
      });
    });
  }

  toNgbDate(date: Date) {
    if (!date) return null;
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  ngbToDate(ngbDate: any) {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  hexToRGB(hex: string, alpha: number | undefined = 1) {
    hex = hex.toUpperCase();

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
