<div class="container mt-2">
  <div class="row justify-content-center">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <div class="heading-panel mb-4">
        <h4 class="main-title text-center">
          {{ "FORGOTPWD.RESTORE_PWD" | translate }}
        </h4>
      </div>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12 mb-3">
            {{ "FORGOTPWD.INSTR" | translate }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <input
                required
                formControlName="email"
                [placeholder]="'COMMON.EMAIL' | translate"
                class="form-control"
                type="email"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <button class="btn btn-outline-dark btn-lg" type="submit">
              {{ "COMMON.SENDREQUEST" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
