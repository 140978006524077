import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { JwtService, UserService } from '../services';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const exclude = ['assets/', 'master/', 'auth/login'];
    const headersConfig: any = {};
    if (req.url.indexOf('upload-pic') == -1) {
      headersConfig['Content-Type'] = 'application/json';
    }

    headersConfig['Accept'] = 'application/json';
    const token = this.jwtService.getToken();
    const jwtHelper = new JwtHelperService();

    if (!exclude.some((x) => req.url.indexOf(x) > -1)) {
      if (!token || jwtHelper.isTokenExpired(token)) {
        this.userService.purgeAuth();
        this.router.navigate(['login']);
      }
    }

    if (token) {
      // tslint:disable-next-line: no-string-literal
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
