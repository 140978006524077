import {
    HttpInterceptor, HttpHandler
    , HttpRequest, HttpSentEvent
    , HttpHeaderResponse, HttpEvent, HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService) { }
    // Note: Add API url to ingore the Loader. Mostly use for background APIs call
    ignoreFor = ['assets/i18n', '/item/list?'];
    /**
     * use this varible to show spiner for all request
     */
    private requests: HttpRequest<any>[] = [];
    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
    }

    /**
     * @description It is a default method of HttpInterceptor. We are using this method to add the spinner in all api request.
     * If you dont want to add spinner in any api call then you can add this path to ingoreFor array.
     * @param req Httprequest
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.ignoreFor.some(a => req.url.includes(a))) {
            return next.handle(req);
        } else {
            this.requests.push(req);
            const hide = () => {
                if (this.requests.length === 0) {
                    this.loaderService.stop();
                }
            };
            const show = () => {
                if (this.requests.length > 0) {
                    this.loaderService.start();
                }
            };
            /* for showing and hiding loader in each http event call */
            return next.handle(req).pipe(
                tap((a) => {
                    if (a.type === HttpEventType.Sent) {
                        show();
                    }
                    if (a.type === HttpEventType.Response) {
                        this.removeRequest(req);
                        hide();
                    }
                }, () => {
                    this.requests = [];
                    hide();
                }, () => {
                    this.removeRequest(req);
                    hide();
                }
                ),
            );
        }
    }
}
