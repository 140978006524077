import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bespoke-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss']
})
export class SiteMapComponent implements OnInit, OnDestroy {
 
  constructor(
    public translate: TranslateService,
  ) {
   
  }
 
  ngOnInit(): void {
    
  }
 
  ngOnDestroy(): void {
    
  }
}
