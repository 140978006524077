import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'format',
})
@Injectable()
export class FormatPipe implements PipeTransform {
  transform(value: any, type: string): string | null {
    if (type == 'bool') {
      if (
        value == true ||
        value == 1 ||
        value == '1' ||
        value == 'TRUE' ||
        value == 'true'
      )
        return 'Yes';
      else return 'No';
    } else if (type == 'date') {
      let date = new DatePipe('en-US').transform(value, 'mediumDate');
      if (this.isInvalidDate(date)) return '';
      return date;
    } else if (type == 'date-time') {
      let date = new DatePipe('en-US').transform(value, 'MMM dd, yyyy hh:mm a');
      if (this.isInvalidDate(date)) return '';
      return date;
    } else if (type == 'date-short') {
      //https://www.concretepage.com/angular-2/angular-2-date-pipe-example

      var currDate = new Date();
      var valDate = new Date(value);

      var df = 'mediumDate';

      if (currDate.getDate() == valDate.getDate()) {
        df = 'shortTime';
      } else if (currDate.getFullYear() == valDate.getFullYear()) {
        df = 'dd MMM';
      }
      let date = new DatePipe('en-US').transform(value, df);

      if (this.isInvalidDate(date)) return '';
      return date;
    } else if (type == 'time') {
      let date = new DatePipe('en-US').transform(value, 'hh:mm a');

      if (this.isInvalidDate(date)) return '';
      return date;
    } else if (type == 'integer') {
      var num = Number(value);
      return Math.round(num).toString();
    } else if (type == 'two-decimal') {
      if (isNaN(value)) return '';
      var num = parseFloat(value);
      return num.toFixed(2);
    } else return value;
  }

  isInvalidDate(date: string | null) {
    return (
      !date ||
      date.indexOf('Jan 1, 1') > -1 ||
      date.indexOf('1753') > -1 ||
      date.indexOf('1900') > -1
    );
  }
}
