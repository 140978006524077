import { Injectable } from '@angular/core';


@Injectable()
export class JwtService {

  getToken(): string {
    // tslint:disable-next-line: no-string-literal
    return window.localStorage['jwtToken'];
  }

  saveToken(token: string): void {
    // tslint:disable-next-line: no-string-literal
    window.localStorage['jwtToken'] = token;
  }

  destroyToken(): void {
    window.localStorage.removeItem('jwtToken');
  }

}
