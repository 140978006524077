import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from '../home/home.component';
import { AuthComponent } from './login-register/auth.component';
import { NoAuthGuard } from './no-auth-guard.service';
import { VerificationComponent } from './verification/verification.component';
import { SiteMapComponent } from './site-map/site-map.component';

const routes: Routes = [
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'register',
    component: AuthComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'verification',
    component: VerificationComponent,
  },
  {
    path: 'sitemap',
    component: SiteMapComponent,
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
