import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private userService: UserService,
    private translate: TranslateService
  ) {
    translate.addLangs(['en', 'fi', 'sv']);
    let lang = window.localStorage['lang'];
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      const browserLang = translate.getBrowserLang();
      lang = browserLang.match(/en|fi|sv/) ? browserLang : 'en';
      translate.use(lang);
    }
    translate.onLangChange.subscribe(() => {
      window.localStorage['lang'] = translate.currentLang;
    });
  }

  ngOnInit(): void {
    this.userService.populate();
  }
}
