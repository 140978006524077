import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService, AuthGuard, GenericService, JwtService, UserService } from './services';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { LoaderInterceptor } from './interceptors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ApiService,
    AuthGuard,
    JwtService,
    UserService,
    GenericService
  ]
})
export class CoreModule { }
